import React from 'react';
import {
  AboutSection,
  ArticlesSection,
  ContactSection,
  HeroSection,
  InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from 'gatsby-theme-portfolio-minimal';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';

export default function IndexPage() {
  return (
    <>
      <Seo title="Viral Ruparel - About" />
      <Page useSplashScreenAnimation>
        <Section heading={'About - The Person'}>
          <p>
            <b>Viral Ruparel</b> is a lead software engineer, a mentor, and an
            entrepreneur. He has worked with tech giants like IBM and a Silicon
            Valley startup called{' '}
            <a href="https://runalloy.com" target="_blank">
              <u>Alloy Automaton</u>
            </a>
            . Today, he is in the process of starting his own business.
          </p>
          <p>
            Viral provides career advice to engineering students to help them
            kickstart their professional journey right from their college years.
          </p>
          <h3>What Does Viral Do? </h3>
          <p>
            Viral provides mentorship and career consultation to 3rd and 4th
            year engineering students and helps them reach IIT and IIM
            graduates. Aside from that, he also assists startups to get angel
            funding to grow their businesses through a fellowship program.
            Through the fellowship program, he provides mentorship and resources
            to help entrepreneurs turn their ideas into reality.
          </p>
          <h3>Why Did Viral Start The Mentorship Program? </h3>
          <p>
            Every year, millions of engineers pass out engineering degrees with
            no job offers in their hands. Many even struggle to get low-paying
            jobs in the saturated market. The reason is that college did not
            prepare them enough to face reality.{' '}
            <u>
              Since he graduated from a 3rd tier engineering institute in
              Ahmedabad, Viral knows all the struggles
            </u>
            . The struggle is real when getting a foot on the career ladder.
            However, Viral believes that opportunities are global. Anybody can
            get the chance if they're willing to grab it.
          </p>
        </Section>
        <ContactSection sectionId="contact" heading="Contact" />
      </Page>
    </>
  );
}
